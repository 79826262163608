import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class appImageApi extends BaseApi {
  async uploadImageByLambdaEdge(
    requestBody: APIPaths["/app/image/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/image/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/image/new", requestBody)).data;
  }

  async fetchBase64ImageByLambdaEdge(
    requestBody: APIPaths["/app/image/base64/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/image/base64/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/image/base64/new", requestBody)).data;
  }
}
