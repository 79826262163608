import { initializeApp, getApps, getApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};
const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();

export const firebaseGetAuth: Auth = getAuth(firebaseApp);
if (process.env.FIREBASE_AUTH_TENANT_ID) {
  firebaseGetAuth.tenantId = process.env.FIREBASE_AUTH_TENANT_ID;
}
firebaseGetAuth.languageCode = "ja";

export const APP_ENV = process?.env?.APP_ENV || "";
export const API_URL = process.env.API_URL || "";
export const db = getFirestore(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

export default firebaseApp;
