import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import * as React from "react";

export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || "";
export const existsGaId = GA_ID !== "";

type ContactEvent = {
  action: "submit_form";
  category: "contact";
};
type ClickEvent = {
  action: "click";
  category: "other";
};
export type Event = (ContactEvent | ClickEvent) & {
  label?: Record<string, string | number | boolean>;
  value?: string;
};

// PVを測定する
export const pageview = (path: string) => {
  if (!existsGaId) return;
  if (typeof window.gtag !== "undefined") {
    window.gtag("config", GA_ID, {
      page_path: path,
    });
  }
};

// GAイベントを発火させる
export const gtagEvent = ({ action, category, label, value = "" }: Event) => {
  if (!existsGaId) return;
  window.gtag("event", action, {
    event_category: category,
    event_label: label ? JSON.stringify(label) : "",
    value,
  });
};

// _app.tsx で読み込む
export const usePageView = () => {
  const router = useRouter();
  useEffect(() => {
    if (!existsGaId) return;
    const handleRouteChange = (path: string) => pageview(path);
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

// _app.tsx で読み込む
export const GoogleAnalytics = () => (
  <>
    {existsGaId && (
      <>
        <Script defer src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`} strategy="afterInteractive" />
        <Script
          id="gtm_script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GA_ID}');
            `,
          }}
        />
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GA_ID}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </>
    )}
  </>
);
