import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class StoreApi extends BaseApi {
  async getStoreList(
    parameters: APIPaths["/app/store/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/store/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/store/list", {
        params: parameters,
      })
    ).data;
  }

  async getStoreFavoriteList(
    parameters: APIPaths["/app/store/favorite/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/store/favorite/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/store/favorite/list", {
        params: parameters,
      })
    ).data;
  }

  async getStoreById(
    id: APIPaths["/app/store/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/app/store/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/store/${id}`)).data;
  }

  async putStoreFavoriteById(
    id: APIPaths["/app/store/{id}/favorite"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/app/store/{id}/favorite"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/store/${id}/favorite`)).data;
  }

  async deleteStoreFavoriteById(
    id: APIPaths["/app/store/{id}/favorite"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/app/store/{id}/favorite"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/store/${id}/favorite`)).data;
  }
}
