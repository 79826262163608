import BaseFacebookApi from "./base-facebook-api";

export class FacebookApi extends BaseFacebookApi {
  // for auth
  async getMyFacebookPageInfo() {
    return await this.get(`/me/accounts`);
  }
  async getMyInstagramBusinessID(pageId: string) {
    return await this.get(`/${pageId}?fields=instagram_business_account`);
  }
  async getMyInstagramInfo(igId: string) {
    return await this.get(
      `${igId}?fields=biography,id,ig_id,followers_count,follows_count,name,profile_picture_url,username,website,media_count`,
    );
  }
  async getMyFacebookTokenPermissions() {
    return await this.get(`/me/permissions`);
  }
  // for insights
  async getAudienceCityAgeAndGenderData(instagramId: string) {
    return await this.get(
      `/${instagramId}/insights?metric=audience_city,audience_country,audience_gender_age,audience_locale&period=lifetime`,
    );
  }
  async getOnlineFollowersData(instagramId: string, sinceTime: number) {
    return await this.get(`/${instagramId}/insights?metric=online_followers&period=lifetime&since=${sinceTime}`);
  }
  async getBenchmarkAccountsData(instagramId: string, benchmark_name: string) {
    return await this.get(
      `/${instagramId}?fields=business_discovery.username(${benchmark_name}){biography,id,ig_id,followers_count,follows_count,name,profile_picture_url,username,website,media_count,media{caption,id,media_product_type,media_type,media_url,permalink,timestamp,username,comments_count,like_count}}`,
    );
  }
  async getMyReachCountByRecentWeek(instagramId: string) {
    return await this.get(`/${instagramId}/insights?metric=reach&period=week`);
  }
  async getMyReachCountByRecent28Days(instagramId: string) {
    return await this.get(`/${instagramId}/insights?metric=reach&period=days_28`);
  }

  // コメント系
  async getMediaInfo(instagramId: string) {
    return await this.get(
      `/${instagramId}/media?fields=media_type,media_product_type,permalink,caption,timestamp,like_count,comments_count,thumbnail_url,is_comment_enabled,media_url`,
    );
  }
  async getMediaComment(mediaId: string) {
    return await this.get(`/${mediaId}/comments`);
  }
  async postMediaComment(mediaId: string, message: string) {
    return await this.post(`/${mediaId}/comments?message=${message}`);
  }
  async deleteMediaComment(commentId: string) {
    return await this.delete(`/${commentId}`);
  }
}
