import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class InfluencerApi extends BaseApi {
  async getProfile(): Promise<
    APIPaths["/app/influencer/me"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/app/influencer/me")).data;
  }

  async patchProfile(
    requestBody: APIPaths["/app/influencer/me"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/influencer/me"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch("/app/influencer/me", requestBody)).data;
  }

  async putCancelProfile(
    requestBody: APIPaths["/app/influencer/me/cancel"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/influencer/me/cancel"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put("/app/influencer/me/cancel", requestBody)).data;
  }

  async deleteAddress(): Promise<
    APIPaths["/app/influencer/me/address"]["delete"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.delete("/app/influencer/me/address")).data;
  }
  // 利用規約に同意するときのAPI
  async influencerActivate(): Promise<
    APIPaths["/app/influencer/me/activate"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put("/app/influencer/me/activate")).data;
  }
}
