import { useAtom } from "jotai";
import localforage from "localforage";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";

import { useAuthentication } from "@/custom-hooks/use-authentication";
import { homePath } from "@/global-state/influencer-app-path";
import { authUserAtom, idTokenAtom } from "@/global-state/jotai-atom";
import { QueryKeys } from "@/global-state/react-query-keys";

type Props = {
  children: React.ReactNode;
};
const AuthProvider: React.FC<Props> = (props) => {
  const { syncIdToken, syncProfile } = useAuthentication();
  const queryClient = useQueryClient();
  const router = useRouter();
  const [authUser] = useAtom(authUserAtom);
  const [idToken] = useAtom(idTokenAtom);

  const handleVisibilityChange = async () => {
    if (!document.hidden) {
      await syncIdToken();
    }
  };
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    (async () => {
      await queryClient.invalidateQueries(QueryKeys.fetchInfluencerTicket);
      await syncProfile();
    })();
  }, [idToken]);

  useEffect(() => {
    if (!authUser?.id) {
      return;
    }
    const resetInterval = setInterval(() => syncIdToken(), 10 * 60 * 1000); // 10分間
    return () => clearInterval(resetInterval);
  }, [authUser?.id]);

  return <>{props.children}</>;
};

export default AuthProvider;
