export const rootPath = "";

// 画像系
const baseImagePath = "/app-images";
export const initialTitleImage = `${baseImagePath}/initial-unit-title.svg`;
export const baseUnitImage = `/images/imgLogo.png`;
export const heartActiveImage = `/images/HeartActive.png`;
export const heartInactiveImage = `/images/HeartInactive.png`;
export const noImageUrl3x4 = "/images/no-image_3x4.png";
export const noImageUrl16x9 = "/images/no-image_16x9.png";
export const logoImageUrl = "/icon-256x256.png";

// アプリページ系
export const loginPath = `${rootPath}/auth/login`;
export const signupPath = (id: string) => `/auth/${id}`;
export const emailAuthenticationPath = `${rootPath}/auth/email-authentication`;
export const passwordResetPath = `${rootPath}/auth/password-reset`;
export const completePassResetPath = `${rootPath}/auth/complete-password-reset`;

export const welcomePath = `${rootPath}/welcome`;

export const myPagePath = `${rootPath}/mypage`;
export const accountInfoPath = `${myPagePath}/account-info`;
export const editSnsAccountPath = `${myPagePath}/edit-sns-account`;
export const shippingAddressPath = `${myPagePath}/edit-shipping-address`;
export const subscribeEmailPath = `${myPagePath}/edit-subscribe-email`;
export const resetEmailPath = `${myPagePath}/reset-email`;
export const mypagePasswordResetPath = `${myPagePath}/password-reset`;
export const myInstagramAccountsPath = `${myPagePath}/my-instagram-accounts`;
export const leaveMemberPath = `${myPagePath}/leave-member`;

// Instagram連携系
export const instagramConnectPath = `${myPagePath}/instagram-accounts-connect`;
export const instagramAccountInsightPath = `${myPagePath}/my-instagram/account-insight`;

export const ticketsPath = (query: string) => `${rootPath}/tickets/${query}`;
export const shopPath = (query: string) => `${rootPath}/shops/${query}`;
export const ticketsPreviewPath = (query: string) => `${rootPath}/tickets/preview?id=${query}`;
export const shopPreviewPath = (query: string) => `${rootPath}/shops/preview?id=${query}`;

export const homePath = `${rootPath}/home`;
export const homeTicketsSearchPath = `${rootPath}/home/ticket-search`;

export const notificationPath = "/notification";
export const notificationDetailPath = (id: string) => `/notification/${id}`;

export const myTicketsFavoritePath = `/my-favorite`;

export const influencerTicketPath = "/influencer-ticket";
export const influencerTicketDetailPath = (influencerTicketId: string) =>
  `${influencerTicketPath}/${influencerTicketId}`;

// 利用規約など、その他
export const termsPath = "https://drive.google.com/file/d/1yOL-hlTm-sdCGt4XUeXWyJ5pIrNtO3Wq/view";
export const privacyPolicyPath = "https://unit-g.com/privacy_policy/";
export const operationManualPath = "https://help.unit-g.com/influencer/";
export const serviceCompanyPath = "https://unit-g.com/corporation/";
export const customerSupportPath = "https://service.unit-g.com/contact/";

// InstagramビジネスアカウントとFacebookページを連携する。
export const INSTAGRAM_BUSINESS_SETTING_PATH =
  "https://www.facebook.com/help/instagram/502981923235522/?helpref=uf_share";
export const FACEBOOK_PAGE_SETTING_PATH = "https://www.facebook.com/pages/creation/?ref_type=facebook_business_website";
export const LINKED_INSTAGRAM_FACEBOOK_PATH = "https://ja-jp.facebook.com/business/help/898752960195806";
