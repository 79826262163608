import ColorPalette from "./color-palette";

export const FontColor = {
  basic: ColorPalette.tuatara,
  calm: ColorPalette.naturalGray,
  doveGray: ColorPalette.doveGray,
  sub: ColorPalette.nevada,
  subThick: ColorPalette.rollingStone,
  subtle: ColorPalette.mercury,

  inactive: ColorPalette.naturalGray, // 非活性
  white: ColorPalette.white,
  black: ColorPalette.black,
  clickable: ColorPalette.dodgerBlue,
  green: ColorPalette.appleGreen,
  caution: ColorPalette.orangeade,

  // SNSアイコン
  youtube: ColorPalette.red,
} as const;

export const FontFamily = {
  kintoSans: "kinto-sans",
  notoSansJp: "Noto Sans JP, sans-serif",
  roboto: "Roboto, sans-serif",
} as const;

export const FontSize = {
  enlargement: "24px",
  title: "16px",
  headline: "15px",
  caption: "14px",
  subhead: "13px",
  label: "12px",
  description: "11px",
  note: "10px",
} as const;

/**************************************************
 * 一般的な太さ名との対応
 * @See https://developer.mozilla.org/ja/docs/Web/CSS/font-weight#%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E5%A4%AA%E3%81%95%E5%90%8D%E3%81%A8%E3%81%AE%E5%AF%BE%E5%BF%9C
 **************************************************/
export const FontWeight = {
  semiLight: 350,
  normal: 400,
  medium: 500,
  bold: 700,
} as const;
