/**************************************************
 * カラーパレット
 * 下記を参考にして色名を決めると良い
 * @see https://chir.ag/projects/name-that-color
 **************************************************/
export default {
  black: "#000000",
  tuatara: "#2B2A27",
  nevada: "#707173",
  rollingStone: "#797A7C",
  naturalGray: "#93918F",
  doveGray: "#717171",
  mercury: "#E2E2E2",
  iron: "#E4E4E5",
  gallery: "#EBEBEB",
  alabaster: "#F7F7F7",
  white: "#FFFFFF",
  pictonBlue: "#32D3EB", // Note: UNITのブランドカラー（グラデーションに使用）
  dodgerBlue: "#3AB7FF", // Note: UNITのブランドカラー
  porcelain: "#F5F7F8",
  appleGreen: "#5EC43B",
  creamCan: "#F6CA55", // Note: 来店ラベル
  tradeWind: "#51B5AB", // Note: 通販ラベル
  red: "#FF0000",
  orangeade: "#FF7663",
  persimmoRed: "#FF5555",
  yellow: "#FABF26",
  yellowLight: "#FFFCE0",
  fairPink: "#FFECEC",
} as const;
