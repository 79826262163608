import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class TicketApi extends BaseApi {
  async getTicketList(
    parameters: APIPaths["/app/ticket/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/ticket/list", {
        params: parameters,
      })
    ).data;
  }

  async getTicketById(
    id: APIPaths["/app/ticket/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/app/ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/ticket/${id}`)).data;
  }

  async putTicketById(
    id: APIPaths["/app/ticket/{id}/request"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/app/ticket/{id}/request"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/ticket/{id}/request"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/ticket/${id}/request`, requestBody)).data;
  }
}
