import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class SpotlightApi extends BaseApi {
  async getSpotlightList(
    parameters: APIPaths["/app/spotlight/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/spotlight/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/spotlight/list", {
        params: parameters,
      })
    ).data;
  }

  async getSpotlightById(
    id: APIPaths["/app/spotlight/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/app/spotlight/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/spotlight/${id}`)).data;
  }
}
