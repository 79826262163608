import axios, { AxiosRequestConfig } from "axios";

export default class BaseFacebookApi {
  accessToken: string | undefined;

  constructor(accessToken?: string | null) {
    if (accessToken) this.accessToken = accessToken;
  }

  protected get axiosApiConfig() {
    return {
      baseURL: "https://graph.facebook.com/v16.0",
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "json",
      params: {
        access_token: this.accessToken || "",
      },
    } as AxiosRequestConfig;
  }

  protected get get() {
    return axios.create(this.axiosApiConfig).get;
  }

  protected get post() {
    return axios.create(this.axiosApiConfig).post;
  }

  protected get put() {
    return axios.create(this.axiosApiConfig).put;
  }

  protected get delete() {
    return axios.create(this.axiosApiConfig).delete;
  }
}
