import { zeroPad } from "./common-utils";

export const formatJpDate = (date?: string | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日` : "";
};

export const formatSlashDateForNumber = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)}` : "";
};

export const formatJpDateForNumber = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日 ` : "";
};

export const formatSlashDateTimeForNumber = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt
    ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)} ${zeroPad(
        dt.getHours(),
        2,
      )}:${zeroPad(dt.getMinutes(), 2)}`
    : "";
};
export const formatJpDateTimeForNumber = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt
    ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日 ${zeroPad(
        dt.getHours(),
        2,
      )}:${zeroPad(dt.getMinutes(), 2)}`
    : "";
};

export const formatApiDate = (date?: Date | null) => {
  const dt = new Date(date ?? "");
  return dt ? `${dt.getFullYear()}-${zeroPad(dt.getMonth() + 1, 2)}-${zeroPad(dt.getDate(), 2)}` : "";
};

export const formatApiTime = (date?: Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${zeroPad(dt.getHours(), 2)}:${zeroPad(dt.getMinutes(), 2)}` : "";
};

export const formatApiTimeForNumber = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt ? `${zeroPad(dt.getHours(), 2)}:${zeroPad(dt.getMinutes(), 2)}` : "";
};

export const formatSlashDateStrings = (date?: string | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)}` : "";
};

export const validateIsExpired = (endDate: string | null) => {
  if (!endDate) return false;
  const targetEndDate = new Date(endDate);
  targetEndDate.setHours(0, 0, 0, 0);
  targetEndDate.setDate(targetEndDate.getDate() + 1);
  return new Date().getTime() > new Date(targetEndDate).getTime();
};

export const mergeDateAndTime = (date: Date | null, time: Date | null): Date | null => {
  if (!date || !time) {
    return null;
  }
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds(),
  );
};

export const calculateTimeDiff = (createdAt: number) => {
  const actionDate = new Date(createdAt * 1000);
  const currentDate = new Date();
  const diff = currentDate.getTime() > actionDate.getTime() ? currentDate.getTime() - actionDate.getTime() : 0;
  const timeDiff = new Date(diff);

  if (timeDiff.getUTCFullYear() - 1970 > 0) {
    return `${actionDate.getFullYear()}/${zeroPad(actionDate.getMonth() + 1, 2)}/${zeroPad(actionDate.getDate(), 2)}`;
  }
  if (timeDiff.getUTCMonth() > 0) {
    const diffMonth = timeDiff.getUTCMonth();

    return `${diffMonth}ヶ月前`;
  }
  if (timeDiff.getUTCDate() - 1 > 0) {
    const diffDay = timeDiff.getUTCDate() - 1;

    return `${diffDay}日前`;
  }
  if (timeDiff.getUTCHours() > 0) {
    const diffHours = timeDiff.getUTCHours();

    return `${diffHours}時間前`;
  }
  if (timeDiff.getUTCMinutes() > 0) {
    const diffMinutes = timeDiff.getUTCMinutes();

    return `${diffMinutes}分前`;
  }
  if (timeDiff.getUTCSeconds() > 10) {
    const diffSeconds = timeDiff.getUTCSeconds();

    return `${diffSeconds}秒前`;
  }
  if (timeDiff.getUTCSeconds() <= 10) {
    return "たった今";
  }
};

export const sortSchedules = <T extends { createdAt: number }>(schedules: T[]): T[] => {
  const sortedSchedules = [...schedules].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
  return sortedSchedules;
};
