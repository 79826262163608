import { jaJP } from "@mui/material/locale";
import { createTheme, ThemeOptions } from "@mui/material/styles";

import { appColor } from "./app-colors";

// Material-UIパーツのテーマ
export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#3AB7FF",
    },
    secondary: {
      main: "#788782",
    },
    error: {
      main: "#DE3E44",
    },
    background: {
      default: "#fff",
    },
  },
};

export const muiTheme = createTheme(themeOptions, jaJP);
