import { css } from "@emotion/react";

import { appColor } from "./app-colors";
import { appTypo } from "./app-typography";

export const CustomMuiButton = css`
  font-family: ${appTypo.font.kintoSans};
  color: ${appColor.App.white};
  text-transform: none;
  background: ${appColor.Button.primary.color};
  border-radius: 8px;
  opacity: 0.95;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  padding: 16px;
  &:hover {
    background: ${appColor.Button.primary.color};
    opacity: 1;
  }
  &.Mui-disabled {
    opacity: 0.4;
    color: ${appColor.App.white};
    background: ${appColor.Button.primary.color};
  }
  & a:focus {
    color: ${appColor.App.white};
  }
`;
export const CustomMuiButtonRed = css`
  ${CustomMuiButton};
  background: ${appColor.App.accent};
  &:hover {
    background: ${appColor.App.accent};
  }
  &.Mui-disabled {
    background: ${appColor.App.accent};
  }
`;

export const CustomMuiButtonLightBlue = css`
  ${CustomMuiButton};
  background: ${appColor.Accent.lightblue};
  &:hover {
    background: ${appColor.App.welcome};
  }
  &.Mui-disabled {
    background: ${appColor.App.welcome};
  }
`;

export const CustomMuiButtonGradientBlue = css`
  ${CustomMuiButton};
  background: ${appColor.App.welcome};
  &:hover {
    background: ${appColor.App.welcome};
  }
  &.Mui-disabled {
    background: ${appColor.App.welcome};
  }
`;

export const CustomAppWhiteButton = css`
  background: ${appColor.App.white};
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${appColor.Text.primary};
  border: 1px solid ${appColor.Text.primary};
  box-sizing: border-box;
  border-radius: 16px;
  &.MuiButtonBase-root {
    box-shadow: none !important;
  }
  &:hover {
    background: ${appColor.App.white};
    opacity: 1;
  }
  &.Mui-disabled {
    opacity: 0.8;
    background: ${appColor.App.white};
  }
  & a:focus {
    color: ${appColor.App.white};
  }
`;
export const CustomAppTicketButton = (color: string) => css`
  background: ${color};
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${appColor.App.white};
  border: 1px solid ${color};
  box-sizing: border-box;
  border-radius: 16px;
  &.MuiButtonBase-root {
    box-shadow: none !important;
  }
  &:hover {
    background: ${color};
    opacity: 1;
  }
  &.Mui-disabled {
    opacity: 0.8;
    background: ${color};
  }
  & a:focus {
    color: ${appColor.App.white};
  }
`;

export const FormStyled = css`
  span {
    font-family: ${appTypo.font.kintoSans} !important;
  }
  width: 100%;
  border-radius: 8px;
  input {
    background: ${appColor.Border.secondary};
    font-size: 16px !important;
  }
  & .MuiOutlinedInput-root {
    &:hover {
      border-color: ${appColor.App.primary};
    }
    border-radius: 8px;
    fieldset {
      border-color: ${appColor.Border.secondary};
    }
  }
`;

export const FormStyledMultiple = css`
  span {
    font-family: ${appTypo.font.kintoSans} !important;
  }
  width: 100%;
  border-radius: 4px;
  textarea {
    background: ${appColor.Border.secondary};
  }
  & .MuiInputBase-multiline {
    background: ${appColor.Border.secondary};
    &:hover {
      border-color: ${appColor.App.primary};
    }
    border-radius: 4px;
    fieldset {
      border-color: ${appColor.Border.secondary};
    }
  }
`;

export const CustomMuiSelect = css`
  font-family: ${appTypo.font.kintoSans} !important;
  border-radius: 8px;
  & .MuiOutlinedInput-input {
    color: ${appColor.Text.primary};
    background: ${appColor.Border.secondary};
  }
  fieldset {
    border: none;
  }
`;

export const AppCursorPointer = css`
  cursor: pointer;
`;
export const CustomButtonCardStyled = css`
  width: 100%;
  border-radius: 16px;
  &:active {
    box-shadow: none;
  }
`;

export const CustomMuiButtonSmall = css`
  cursor: pointer;
  font-family: ${appTypo.font.notoJp};
  color: ${appColor.App.white};
  background: ${appColor.Button.primary.color};
  opacity: 0.95;
  &:hover {
    background: ${appColor.Button.primary.color};
    opacity: 1;
  }
  &.Mui-disabled {
    opacity: 0.4;
    color: ${appColor.App.white};
    background: ${appColor.Button.primary.color};
  }
  & a:focus {
    color: ${appColor.App.white};
  }
`;
