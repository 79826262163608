// 20000 => 2,0万などに変換するよ。
import { logoImageUrl } from "influencer/src/global-state/influencer-app-path";

export const formatNumJpTypo = (num: number | undefined): string => {
  if (!num) return "";
  const fmt = new Intl.NumberFormat("ja-JP", {
    notation: "compact",
  });
  return fmt.format(num);
};
export const groupBy = <T extends { [key: string]: any }>(objects: T[], key: keyof T): { [key: string]: T[] } =>
  objects.reduce((map, obj) => {
    map[obj[key]] = map[obj[key]] || [];
    map[obj[key]].push(obj);
    return map;
  }, {} as { [key: string]: T[] });

export const convertToWebP = (url?: string | null) => {
  if (!url) {
    return logoImageUrl;
  }
  return `${url}?h=400&w=400&o=webp`;
};

export class UUID {
  static __uuidIte = (function* () {
    const HEXOCTETS = Object.freeze([...Array(0x100)].map((e, i) => i.toString(0x10).padStart(2, "0").toUpperCase()));
    const VARSION = 0x40;
    const VARIANT = 0x80;
    const bytes = new Uint8Array(16);
    const rand = new Uint32Array(bytes.buffer);
    for (;;) {
      for (let i = 0; i < rand.length; i++) {
        rand[i] = (Math.random() * 0x100000000) >>> 0;
      }
      yield "" +
        HEXOCTETS[bytes[0]] +
        HEXOCTETS[bytes[1]] +
        HEXOCTETS[bytes[2]] +
        HEXOCTETS[bytes[3]] +
        "-" +
        HEXOCTETS[bytes[4]] +
        HEXOCTETS[bytes[5]] +
        "-" +
        HEXOCTETS[(bytes[6] & 0x0f) | VARSION] +
        HEXOCTETS[bytes[7]] +
        "-" +
        HEXOCTETS[(bytes[8] & 0x3f) | VARIANT] +
        HEXOCTETS[bytes[9]] +
        "-" +
        HEXOCTETS[bytes[10]] +
        HEXOCTETS[bytes[11]] +
        HEXOCTETS[bytes[12]] +
        HEXOCTETS[bytes[13]] +
        HEXOCTETS[bytes[14]] +
        HEXOCTETS[bytes[15]];
    }
  })();
  static randomUUID() {
    return this.__uuidIte.next().value;
  }
}

// 引数はbase64形式の文字列
export const toBlob = (base64: string): Blob | null => {
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);

  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  // Blobを作成
  try {
    const blob = new Blob([buffer.buffer], {
      type: "image/jpeg",
    });
    return blob;
  } catch (e) {
    return null;
  }
};

// ブランクのプロパティを除外する
export const removeBlankProperties = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== "") {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

// 指定したプロパティを除外する
export const omitProperty = (obj: any) => (key: string) => {
  return (({ [key]: undefined, ...newObjct }) => newObjct)(obj);
};
