import { FirebaseError } from "@firebase/util";
import { APICompsSchema, appAuthApi, appImageApi, InfluencerApi } from "@unit/apis";
import crc32 from "crc/crc32";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  deleteUser,
  updatePassword,
} from "firebase/auth";
import { useAtom } from "jotai";
import localforage from "localforage";
import { useRouter } from "next/router";
import { useCallback } from "react";

import { useMeApi } from "@/custom-hooks/apis/use-me-api";
import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { API_URL, firebaseGetAuth } from "@/global-state/firebase-settings";
import { homePath, loginPath } from "@/global-state/influencer-app-path";
import { authUserAtom, idTokenAtom, initializingAtom, loadingAtom } from "@/global-state/jotai-atom";
import { LocalStorageKeys } from "@/global-state/localstorage-keys";

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = LocalStorageKeys.LOCAL_STORAGE_ACCESS_TOKEN_KEY;
const LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY = LocalStorageKeys.LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY;

export const useAuthentication = () => {
  const router = useRouter();
  const { setAppSnackbar } = useAppSnackbar();
  const [, setLoading] = useAtom(loadingAtom);
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [, setAuthUser] = useAtom(authUserAtom);
  const [, setInitializing] = useAtom(initializingAtom);
  const { updateProfileMe, leaveMember } = useMeApi();

  const resetAuth = useCallback(async () => {
    setAuthUser(null);
    setIdToken(undefined);
    setLoading(false);
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, "");
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, "");
    await signOut(firebaseGetAuth).catch((error) => {
      setAppSnackbar(error?.message || "error", { error: true });
    });
    if (
      !["/", "/tickets/preview", "/shops/preview", "/home/preview"].includes(router.pathname) &&
      !router.pathname.startsWith("/auth")
    ) {
      setTimeout(() => router.push(loginPath));
    }
  }, [router.pathname]);

  const syncIdToken = useCallback(async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    const expiresAtStr = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY);
    if (accessToken && expiresAtStr) {
      const now = new Date().getTime();
      const expiresAt = parseInt(expiresAtStr);
      if (now < expiresAt) {
        setIdToken(accessToken);
        return accessToken;
      }
    }

    return new Promise<string | null>((resolve) => {
      firebaseGetAuth.onAuthStateChanged(async (user) => {
        let accessToken = "";
        try {
          accessToken = (await user?.getIdToken(true)) || "";
        } catch (e: any) {
          console.warn(e);
          if (e.code === "auth/user-token-expired") {
            return resetAuth();
          }
          return;
        }
        if (!accessToken) {
          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, "");
          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, "");
          resolve(null);
          return;
        }

        // 有効期限は取得したタイミングから1時間以内だが、アプリ上では余裕を持って、30分以内とする
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, `${new Date().getTime() + 30 * 60 * 1000}`);
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);

        await setIdToken(accessToken);
        resolve(accessToken);
      });
    });
  }, []);

  const syncProfile = useCallback(async () => {
    setLoading(true);
    try {
      const accessToken = await syncIdToken();
      if (!accessToken) {
        await resetAuth();
        return;
      }
      const influencerApi = new InfluencerApi(API_URL, accessToken);
      const member = await influencerApi.getProfile();
      await setAuthUser(member.object);
      if (["canceled", "disabled"].includes(member.object.status)) {
        if (member.object.status === "canceled") {
          setAppSnackbar("新規登録をお願いします", { warn: true });
        } else {
          setAppSnackbar("アカウントが停止中です", { warn: true });
        }
        await resetAuth();
        return;
      }

      setTimeout(async () => {
        if (member.object.status === "waiting") {
          const checkApi = new InfluencerApi(API_URL, accessToken);
          await checkApi.influencerActivate();
        }
        try {
          const authApi = new appAuthApi(API_URL, accessToken);
          await authApi.syncSignedInAt();
        } catch (e: any) {
          console.error(e?.response?.data?.message || e?.response?.data?.devMessage);
        }
      });

      if (!router.asPath || ["/"].includes(router.asPath) || router.asPath.startsWith("/auth")) {
        await router.push(homePath);
      }
    } catch (e: any) {
      console.error(e?.response?.data?.message || e?.response?.data?.devMessage);
      await resetAuth();
    } finally {
      setInitializing(false);
      setLoading(false);
    }
  }, [idToken]);

  const authSync = useCallback(async () => {
    await syncIdToken();
    setTimeout(() => syncProfile());
  }, []);

  const googleProvider = new GoogleAuthProvider();
  const authWithGoogle = useCallback(async (signUp?: boolean) => {
    setLoading(true);
    signInWithPopup(firebaseGetAuth, googleProvider)
      .then(async (cred) => {
        const linkUser = cred.user;
        const accessToken = (await linkUser.getIdToken(true)) || undefined;
        const authApi = new appAuthApi(API_URL, accessToken);
        const registerMe = await authApi.checkAuthStatus();
        if (signUp) {
          if (registerMe.status === "active") return authSync();
          const email = linkUser.email || "";
          const displayName = linkUser?.displayName || linkUser.email || "";
          const signUpApi = new appAuthApi(API_URL, accessToken);
          await signUpApi
            .influencerSignUp({
              email: email,
              displayName: displayName,
            })
            .then(async () => {
              if (linkUser?.photoURL) {
                const imageUpApi = new appImageApi(API_URL, accessToken);
                const influencerApi = new InfluencerApi(API_URL, accessToken);
                const imageSrc = await imageUpApi.uploadImageByLambdaEdge({
                  imageUrl: linkUser?.photoURL,
                });
                await influencerApi.patchProfile({
                  avatarUrl: imageSrc.imageUrl,
                });
              }
              await authSync();
            })
            .catch(async (e) => {
              console.log(e);
              await deleteUser(linkUser);
              return resetAuth();
            });
        } else {
          if (registerMe.status === "active") {
            return authSync();
          } else {
            setAppSnackbar("新規登録をお願いします", { error: true });
            await deleteUser(linkUser);
            return resetAuth();
          }
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.code === "auth/popup-closed-by-user") {
          return resetAuth();
        } else if (e.code === "auth/account-exists-with-different-credential") {
          setAppSnackbar(
            "すでに同じメールアドレスのFacebookアカウントで連携されているため、Facebookアカウントでの再ログインが必要です",
            { warn: true },
          );
          return resetAuth();
        } else if (e.code === "auth/network-request-failed") {
          setAppSnackbar("ネットワークエラーが発生しました", { error: true });
          return resetAuth();
        } else {
          setAppSnackbar(e?.message || "error", { error: true });
          return resetAuth();
        }
      });
  }, []);

  const facebookProvider = new FacebookAuthProvider();
  facebookProvider.addScope("public_profile");
  facebookProvider.addScope("pages_show_list");
  facebookProvider.addScope("instagram_basic");
  facebookProvider.addScope("pages_read_engagement");
  const authWithFacebook = useCallback(async (signUp?: boolean) => {
    setLoading(true);
    signInWithPopup(firebaseGetAuth, facebookProvider)
      .then(async (cred) => {
        const linkUser = cred.user;
        const accessToken = (await linkUser.getIdToken(true)) || undefined;
        const authApi = new appAuthApi(API_URL, accessToken);
        const registerMe = await authApi.checkAuthStatus();
        if (signUp) {
          if (registerMe.status === "active") return authSync();
          const date = new Date();
          const email = linkUser.email || `${date.getTime()}-${crc32(`${date.getTime()}`).toString(16)}@unit-g.com`;
          const displayName =
            linkUser?.displayName ||
            linkUser.email ||
            `${date.getTime()}-${crc32(`${date.getTime()}`).toString(16)}@unit-g.com`;
          const signUpApi = new appAuthApi(API_URL, accessToken);
          await signUpApi
            .influencerSignUp({
              email: email,
              displayName: displayName,
            })
            .then(async () => {
              if (linkUser?.photoURL) {
                const imageUpApi = new appImageApi(API_URL, accessToken);
                const influencerApi = new InfluencerApi(API_URL, accessToken);
                const imageSrc = await imageUpApi.uploadImageByLambdaEdge({
                  imageUrl: linkUser?.photoURL,
                });
                await influencerApi.patchProfile({
                  avatarUrl: imageSrc.imageUrl,
                });
              }
              setAppSnackbar("Facebookと連携しています。しばらくお待ちください。", { warn: true });
              await authSync();
            })
            .catch(async (e) => {
              console.log(e);
              setAppSnackbar(e?.response?.data?.message || "error", { error: true });
              await deleteUser(linkUser);
              return resetAuth();
            });
        } else {
          if (registerMe.status === "active") return authSync();
          else {
            setAppSnackbar("新規登録をお願いします", { error: true });
            await deleteUser(linkUser);
            return resetAuth();
          }
        }
      })
      .catch((e) => {
        if (e.code === "auth/popup-closed-by-user") {
          return resetAuth();
        } else if (e.code === "auth/account-exists-with-different-credential") {
          setAppSnackbar(
            "すでに同じメールアドレスのGoogleアカウントで連携されているため、Googleアカウントでの再ログインが必要です",
            { warn: true },
          );
          return resetAuth();
        } else if (e.code === "auth/network-request-failed") {
          setAppSnackbar("ネットワークエラーが発生しました", { error: true });
          return resetAuth();
        } else {
          setAppSnackbar(e?.message || "error", { error: true });
          return resetAuth();
        }
      });
  }, []);

  const authSignUpEmail = useCallback(async (props: APICompsSchema["AppSignupRequest"], password: string) => {
    setLoading(true);
    createUserWithEmailAndPassword(firebaseGetAuth, props.email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const accessToken = (await user.getIdToken(true)) || undefined;
        if (!accessToken) return await resetAuth();
        await setIdToken(accessToken);
        const signUpApi = new appAuthApi(API_URL, accessToken);
        try {
          await signUpApi.influencerSignUp(props);
          const registerMe = await signUpApi.checkAuthStatus();
          if (!registerMe || registerMe.status !== "active") {
            return await resetAuth();
          }
          const influencerApi = new InfluencerApi(API_URL, accessToken);
          const member = await influencerApi.getProfile();
          await setAuthUser(member.object);
          setAppSnackbar("登録が完了しました", { success: true });
          return await authSync();
        } catch (error: any) {
          setAppSnackbar(error?.message || "error", { error: true });
          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, "");
          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, "");
          await deleteUser(user);
          return resetAuth();
        }
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setAppSnackbar("既に登録されています", { warn: true });
        } else if (error.code === "auth/network-request-failed") {
          setAppSnackbar("ネットワークエラーが発生しました", { error: true });
          return resetAuth();
        } else {
          setAppSnackbar(error?.message || "error", { error: true });
        }
        return resetAuth();
      });
  }, []);

  // Email&Passwordでのログイン
  const authLoginWithEmail = useCallback(async (email: string, password: string) => {
    setLoading(true);
    signInWithEmailAndPassword(firebaseGetAuth, email, password)
      .then(async (cred) => {
        const linkUser = cred.user;
        const accessToken = (await linkUser.getIdToken(true)) || undefined;
        await setIdToken(accessToken);
        try {
          const checkApi = new InfluencerApi(API_URL, accessToken);
          const member = await checkApi.getProfile();
          if (member.object.status === "disabled") {
            setAppSnackbar("現在利用停止中です", { error: true });
            return resetAuth();
          }
          await setAuthUser(member.object);
          await router.push(homePath);
        } catch (e) {
          return resetAuth();
        }
      })
      .catch((error) => {
        const code = error.code;
        if (code === "auth/user-not-found") {
          setAppSnackbar("未登録のユーザーです。新規登録をお願いします。", { error: true });
        } else if (code === "auth/wrong-password") {
          setAppSnackbar("パスワードが一致しませんでした。", { error: true });
        } else if (code === "auth/too-many-requests") {
          setAppSnackbar("連続して誤ったパスワードが入力されました。お時間を置いて再度お試しください。", {
            error: true,
          });
        } else if (code === "auth/network-request-failed") {
          setAppSnackbar("ネットワークエラーが発生しました", { error: true });
          return resetAuth();
        } else {
          setAppSnackbar(error?.message || "error", { error: true });
        }
        return resetAuth();
      });
  }, []);

  // ログアウト
  const authLogout = useCallback(async () => {
    setLoading(true);
    signOut(firebaseGetAuth)
      .then(async () => {
        setAppSnackbar("ログアウトしました。", { info: true });
        await resetAuth();
      })
      .catch((error) => {
        setAppSnackbar(error?.message || "error", { error: true });
      })
      .finally(() => setLoading(false));
  }, []);

  const authUpdateEmail = useCallback(async (email: string, newEmail: string, password: string) => {
    const user = firebaseGetAuth.currentUser;
    setLoading(true);
    if (!user) {
      return;
    }
    try {
      await updateProfileMe({
        email: newEmail,
      });
      await authLoginWithEmail(newEmail, password);
      return setAppSnackbar("メールアドレスを変更しました", { success: true });
    } catch (e) {
      if (e instanceof FirebaseError) {
        console.error(e);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const authLeaveMember = useCallback(async (reason: string) => {
    setLoading(true);
    try {
      await leaveMember({ reason });
      setAppSnackbar("ユニットをご利用いただきありがとうございました。退会手続きが完了しました。", { success: true });
    } catch (e: any) {
      if (e) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", { error: true });
      }
    } finally {
      await resetAuth();
    }
  }, []);

  const submitPasswordResetEmail = useCallback(async (email: string) => {
    setLoading(true);
    await sendPasswordResetEmail(firebaseGetAuth, email)
      .then(() => {
        setAppSnackbar("パスワード再設定メールを送信しました", { success: true });
      })
      .catch((error) => {
        const code = error.code;
        if (code === "auth/user-not-found") {
          setAppSnackbar("ユーザーが見つかりませんでした。", { error: true });
        } else if (error.code === "auth/network-request-failed") {
          setAppSnackbar("ネットワークエラーが発生しました", { error: true });
          return resetAuth();
        } else {
          setAppSnackbar("メールの送信に失敗しました", { error: true });
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const authResetPassword = useCallback(async (prevPassword: string, newPassword: string) => {
    setLoading(true);
    const user = firebaseGetAuth.currentUser;
    const providerData = user?.providerData.length ? user?.providerData[0].providerId : "";
    if (!user || !user.email || !providerData) return await resetAuth();
    if (providerData === "google.com") {
      return setAppSnackbar("Googleでログインしているためパスワードの変更はできません。", { error: true });
    } else if (providerData === "facebook.com") {
      return setAppSnackbar("Facebookでログインしているためパスワードの変更はできません。", { error: true });
    }
    const credential = await EmailAuthProvider.credential(user.email, prevPassword);
    if (!credential) return resetAuth();
    await reauthenticateWithCredential(user, credential)
      .then(async (cred) => {
        await updatePassword(cred.user, newPassword)
          .then(() => {
            setAppSnackbar("パスワードの更新に成功しました。", { success: true });
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        setAppSnackbar("パスワードの更新に失敗しました。", { error: true });
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const authCheckExistsUserForStoreWebSignup = useCallback(async (email: string) => {
    setLoading(true);
    const res = signInWithEmailAndPassword(firebaseGetAuth, email, "test")
      .catch((error) => {
        const code = error.code;
        console.log(code);
        if (code === "auth/user-not-found") {
          return true;
        } else if (code === "auth/invalid-email") {
          setAppSnackbar("ご入力のメールアドレスの形式が正しくありません", { error: true });
        } else if (code === "auth/wrong-password") {
          setAppSnackbar("ご登録のメールアドレスは既に会員登録済みです", { error: true });
        } else {
          setAppSnackbar("エラーが発生しました", { error: true });
        }
        return false;
      })
      .finally(() => setLoading(false));
    return res;
  }, []);

  return {
    resetAuth,
    syncIdToken,
    syncProfile,
    authSync,
    authWithGoogle,
    authWithFacebook,
    authSignUpEmail,
    authLoginWithEmail,
    authLogout,
    authUpdateEmail,
    authLeaveMember,
    submitPasswordResetEmail,
    authResetPassword,
    authCheckExistsUserForStoreWebSignup,
  };
};
