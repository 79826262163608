export const QueryKeys = {
  getAuthUser: "getAuthUser",
  getGenreList: "getGenreList",
  getAreaList: "getAreaList",
  fetchTicketList: "fetchTicketList",
  fetchAvailableTicketList: "fetchAvailableTicketList",
  fetchExpiredTicketList: "fetchExpiredTicketList",
  fetchTicketListByKeyword: "fetchTicketListByKeyword",
  fetchTicket: "fetchTicket",
  fetchSpotlightList: "fetchSpotlightList",
  fetchStoreList: "fetchStoreList",
  fetchFavoriteStoreList: "fetchFavoriteStoreList",
  fetchStoreById: "fetchStoreById",
  fetchInfluencerTicketList: "fetchInfluencerTicketList",
  fetchInfluencerTicket: "fetchInfluencerTicket",
  fetchInfluencerTicketStatusLog: "fetchInfluencerTicketStatusLog",

  fecthRoomMessage: "fecthRoomMessage",
  fecthAllRoom: "fecthAllRoom",
  fecthMyInstagramAccount: "fecthMyInstagramAccount",
  fecthEmailAuth: "fecthEmailAuth",
  fetchAllNotificationList: "fetchAllNotificationList",
  getNotificationById: "getNotificationById",
  fetchUnreadNotificationCount: "fetchUnreadNotificationCount",

  // for instagram graph api のアプリレビュー申請用
  instagramAccountsForAuth: "instagramAccountsForAuth",
  accountInsights: "accountInsights",
  followerInsights: "followerInsights",
  getMyReachRecentWeek: "getMyReachRecentWeek",
  getMyReachRecent28Days: "getMyReachRecent28Days",
} as const;
