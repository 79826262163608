import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class InfluencerTicketApi extends BaseApi {
  async getInfluencerTicketList(
    parameters: APIPaths["/app/influencer-ticket/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/influencer-ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/influencer-ticket/list", {
        params: parameters,
      })
    ).data;
  }

  async getInfluencerTicketById(
    id: APIPaths["/app/influencer-ticket/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/app/influencer-ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/influencer-ticket/${id}`)).data;
  }

  async putInfluencerTicketOfferRejectionById(
    id: APIPaths["/app/influencer-ticket/{id}/offer-rejection"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/offer-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/influencer-ticket/${id}/offer-rejection`)).data;
  }

  async putInfluencerTicketOfferApprovalById(
    id: APIPaths["/app/influencer-ticket/{id}/offer-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/offer-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/influencer-ticket/${id}/offer-approval`)).data;
  }

  async putInfluencerTicketUseById(
    id: APIPaths["/app/influencer-ticket/{id}/use"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/app/influencer-ticket/{id}/use"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/influencer-ticket/${id}/use`)).data;
  }

  async putInfluencerTicketScheduleById(
    id: APIPaths["/app/influencer-ticket/{id}/schedule"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/app/influencer-ticket/{id}/schedule"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/schedule"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/influencer-ticket/${id}/schedule`, requestBody)).data;
  }

  async putInfluencerTicketPRPostById(
    id: APIPaths["/app/influencer-ticket/{id}/posts"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/app/influencer-ticket/{id}/posts"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/influencer-ticket/{id}/posts"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/influencer-ticket/${id}/posts`, requestBody)).data;
  }

  async putInfluencerTicketRatingById(
    id: APIPaths["/app/influencer-ticket/{id}/rating"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/app/influencer-ticket/{id}/rating"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/influencer-ticket/{id}/rating"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/influencer-ticket/${id}/rating`, requestBody)).data;
  }

  async postInfluencerTicketChatReportById(
    id: APIPaths["/app/influencer-ticket/{id}/chat-report/new"]["post"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/app/influencer-ticket/{id}/chat-report/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/chat-report/new"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/app/influencer-ticket/${id}/chat-report/new`, requestBody)).data;
  }

  async getInfluencerTicketChatReportList(
    id: APIPaths["/app/influencer-ticket/{id}/chat-report/list-all"]["get"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/chat-report/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/app/influencer-ticket/${id}/chat-report/list-all`)).data;
  }
  async postInfluencerTicketNotifyChatMessage(
    id: APIPaths["/app/influencer-ticket/{id}/notify-chat-message"]["post"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/app/influencer-ticket/{id}/notify-chat-message"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths> {
    return (await this.post(`/app/influencer-ticket/${id}/notify-chat-message`, requestBody)).data;
  }
  async getInfluencerTicketStatusLogList(
    id: APIPaths["/app/influencer-ticket/{id}/status-log/list-all"]["get"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/status-log/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/app/influencer-ticket/${id}/status-log/list-all`)).data;
  }
  async putInfluencerTicketUseCancelById(
    id: APIPaths["/app/influencer-ticket/{id}/use-cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/use-cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/influencer-ticket/${id}/use-cancel`)).data;
  }
  async putInfluencerTicketRequestCancelById(
    id: APIPaths["/app/influencer-ticket/{id}/request-cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/app/influencer-ticket/{id}/request-cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/app/influencer-ticket/${id}/request-cancel`)).data;
  }
}
