import { APICompsSchema, APIPaths } from "@unit/apis";
import { atom } from "jotai";

import { InstagramGraphAPIType } from "@/types/FacebookGraphApi";

export const initializingAtom = atom<boolean>(true);
export const loadingAtom = atom<boolean>(false);
export const idTokenAtom = atom<string | undefined>(undefined);

export const instagramAccountsForAuthAtom = atom<InstagramGraphAPIType[]>([]);

export const authUserAtom = atom<APICompsSchema["InfluencerObject"] | null>(null);
export const previousRouterPathAtom = atom<string>("");
export const unreadInfluencerTicketIdListAtom = atom<APICompsSchema["UUIDValue"][]>([]);
export const unreadNotificationCountAtom = atom<number>(0);
export const selectInfluencerTicketIdAtom = atom<{
  chatOpen: boolean;
  influencerTicketId: string;
}>({
  chatOpen: false,
  influencerTicketId: "",
});

export const genresAtom = atom<APICompsSchema["FlatGenreObject"][] | null>(null);
export const areasAtom = atom<APICompsSchema["FlatAreaObject"][] | null>(null);
export const storesAtom = atom<APICompsSchema["StoreObject"][] | null>(null);
export const favoriteStoresAtom = atom<APICompsSchema["StoreObject"][] | null>(null);
export const favoriteHeaderListAtom = atom<{ type: string; value: string }[]>([
  {
    type: "favorite",
    value: "お気に入り",
  },
  {
    type: "all",
    value: "すべての店舗",
  },
]);

export const ticketsHeaderType = atom<0 | 1 | 2>(0);
export const ticketsTypeFilterAtom = atom<"all" | "visit" | "order">("all");
export const ticketsStatusFilterAtom = atom<APICompsSchema["InfluencerTicketStatusEnum"][]>([]);

// for tickets filter
export const ticketsFilterAtom = atom<APIPaths["/app/ticket/list"]["get"]["parameters"]["query"]>({
  offset: 0,
  limit: 1000,
  sortBy: "createdAt-desc",
});
export const ticketsFilterExtraAtom = atom<{
  tabMenu: "new" | "nearDeadline";
  snsAccount: APICompsSchema["SNSAccountTypeEnum"][];
  isAvailable: boolean;
}>({
  tabMenu: "new",
  snsAccount: [],
  isAvailable: false,
});
