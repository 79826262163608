export const appColor = {
  App: {
    primary: "#32D3EB",
    sidenav: "#28a9bc",
    accent: "#DE3E44",
    white: "#FFFFFF",
    blueMain: "#0BA1E7",
    welcome: "linear-gradient(240.63deg, #32d3eb 13.44%, #3ab7ff 95.5%)",
  },
  Text: {
    primary: "#2B2A27", // 主にメインテキストなどに使用
    secondary: "#93918F", // 非アクティブ要素や注釈のテキストなどに使用
    gray: "#C2C2C0",
    white: "#FFFFFF",
    blueMain: "#0BA1E7",
  },
  Border: {
    primary: "#2B2A27", // Divider/Border/Strokeが主にアクティブなときに使用
    secondary: "#F4F4F4", // Divider/Border/Strokeが主に非アクティブなときに使用
    third: "#F1F1F1",
    grayLight: "#E2E2E2",
  },
  Background: {
    primary: "#FFFFFF", // メインバックグラウンドで使用
    secondary: "#F7F7F7", // サブコンテンツのバックグラウンドで使用
    selected: "#FCFCFE", // リストアイテムなどが選択状態のときに使用
    welcome: "#F3FBFF",
    third: "#F4F4F4",
    sidenav: "#F4F3F8",
    chat: "#F8FAFB",
    gray: "#93918F",
    grayMedium: "#EBEBEB",
    grayLight: "#E2E2E2",
  },
  Form: {
    primary: "#FFFFFF", // 活性状態のときに使用
    secondary: "#FCFCFE", // 非活性状態のときに使用
  },
  Button: {
    primary: {
      color: "#2B2A27",
      text: "#FFFFFF",
    }, // メインボタン
    negative: {
      color: "#F4F3F8",
      text: "#B4B4BE",
    }, //主にボタンが非アクティブ状態で使用
    accent: {
      red: "#FF7663",
      gray: "#7F7F89",
      facebook: "#1877F2",
      blue: "#4984bc",
      blueMain: "#4794FF",
      green: "#448c7b",
    },
    social: {
      facebook: "#5E6E9F",
    },
  },
  Accent: {
    blue: "#3AB7FF",
    lightblue: "#32D3EB",
    ultraLightBlue: "#DEF8FC",
    previeBlue: "#4da07b",
    orange: "#FFAA4E",
    red: "#FF7663",
    yellow: "#FBC929",
    gray: "#93918F",
    green: "#7CBC93",
    premium: "#F2C938",
  },
  chart: {
    dot: "#FFFFFF",

    barAgeGenderF: "#FF80AB",
    barAgeGenderM: "#82B1FF",
    barAgeGenderU: "#DDDDDD",

    barFollowerRiseFall: "#A0CBBD",
    lineFollowerAndReachChart: "#E45328",

    areaProfile1: "#FF9800",
    areaProfile2: "#03A9F4",
    areaProfile3: "#FFEB3B",
    areaProfile4: "#673AB7",
    areaProfile5: "#E91E63",
    audienceCity: ["#32D3EB", "#62D9EB", "#91DFEC", "#C1E6ED", "#F0ECEE", "#F7F7F7", "#F4F4F4", "#F1F1F1"],

    groupHashtagBar: "#C6E0D7",
  },
} as const;
