import { css } from "@emotion/react";
import { appTypo } from "./app-typography";
import { appColor } from "./app-colors";

// storybookのためにglobals.cssと合わせなきゃならんぞ。
export const globalStyled = css`
  html {
    font-family: "Roboto", "Noto Sans JP", "Avenir", "Helvetica Neue", "Helvetica", "Arial", "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro", "メイリオ", sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    background-color: #f8fafb;
    font-style: normal;
    font-weight: normal;
    min-height: 100vh;
    word-break: break-all;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }

  .firebase-emulator-warning {
    display: none;
  }
  & .MuiMenu-list {
    max-height: 280px;
  }

  //  react-toastify-css
  .Toastify__toast-container {
    .Toastify__toast {
      min-height: 48px;
    }
    .Toastify__toast-body {
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: ${appColor.Text.white};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background: ${appColor.Text.secondary};
  }
  .Toastify__progress-bar {
    background: ${appColor.App.white};
  }
  .Toastify__close-button > svg {
    fill: ${appColor.App.white};
  }
`;
