import ColorPalette from "./color-palette";
import { css } from "@emotion/react";

export const BorderColor = {
  basic: ColorPalette.tuatara,
  pale: ColorPalette.iron,
  yellow: ColorPalette.yellow,
  subtle: ColorPalette.mercury,
  dodgerBlue: ColorPalette.dodgerBlue,
  porcelain: ColorPalette.porcelain,
  persimnoRed: ColorPalette.persimmoRed,
} as const;

export const BorderStyle = {
  none: css`
    border: none;
  `,
  solidBasic: css`
    border: 1px solid ${BorderColor.basic};
  `,
  strikingPale: css`
    border: 2px solid ${BorderColor.pale};
  `,
  solidSubtle: css`
    border: 1px solid ${BorderColor.subtle};
  `,
  dodgerBlue: css`
    border: 1px solid ${BorderColor.dodgerBlue};
  `,

  /** 区切り線 */
  paleDivider: css`
    border-bottom: 1px solid ${BorderColor.pale};
  `,
  subtleDivider: css`
    border-bottom: 1px solid ${BorderColor.subtle};
  `,
  yellowBasic: css`
    border: 1px solid ${BorderColor.yellow};
  `,
  persimnoRed: css`
    border: 1px solid ${BorderColor.persimnoRed};
  `,
} as const;
