import ColorPalette from "./color-palette";

const gradient = {
  unitBlue: [
    `linear-gradient(0deg, ${ColorPalette.dodgerBlue} 0%, ${ColorPalette.dodgerBlue} 100%)`,
    `linear-gradient(243deg, ${ColorPalette.pictonBlue} 0%, ${ColorPalette.dodgerBlue} 100%);`,
  ],
};

export const BackgroundColor = {
  none: ColorPalette.alabaster, // Appデフォルトの背景色
  lightBasic: ColorPalette.white,
  darkBasic: ColorPalette.tuatara,
  paleGray: ColorPalette.iron,
  accentGray: ColorPalette.gallery,
  red: ColorPalette.persimmoRed,
  fairPink: ColorPalette.fairPink,
  activeBlue: ColorPalette.dodgerBlue, // ボタン要素の活性時などで使用
  yellowBasic: ColorPalette.yellow,
  yellowLight: ColorPalette.yellowLight,
  unselectedGray: ColorPalette.alabaster, // トグルボタンなどの未選択の状態で使用
  accentYellow: ColorPalette.creamCan,
  accentBlueGreen: ColorPalette.tradeWind,
  porcelain: ColorPalette.porcelain,

  grayChip: ColorPalette.naturalGray,
  unitBlue: gradient.unitBlue.join(", "),
} as const;
