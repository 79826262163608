import { APIPaths, InfluencerApi } from "@unit/apis";
import { useAtom } from "jotai";
import { useCallback, useMemo } from "react";

import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { API_URL } from "@/global-state/firebase-settings";
import { idTokenAtom, loadingAtom } from "@/global-state/jotai-atom";

export const useMeApi = () => {
  const { setAppSnackbar } = useAppSnackbar();
  const [, setLoading] = useAtom(loadingAtom);
  const [idToken] = useAtom(idTokenAtom);
  const influencerApi = useMemo(() => new InfluencerApi(API_URL, idToken), [idToken]);

  const fetchProfileMe = useCallback(async () => {
    await setLoading(true);
    try {
      return await influencerApi.getProfile();
    } catch (e: any) {
      setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
        error: true,
      });
    } finally {
      await setLoading(false);
    }
  }, [influencerApi]);

  const updateProfileMe = useCallback(
    async (requestBody: APIPaths["/app/influencer/me"]["patch"]["requestBody"]["content"]["application/json"]) => {
      await setLoading(true);
      try {
        return await influencerApi.patchProfile(requestBody);
      } catch (e: any) {
        setAppSnackbar(e?.response?.data?.message || e?.response?.data?.devMessage || "error", {
          error: true,
        });
      } finally {
        await setLoading(false);
      }
    },
    [influencerApi],
  );

  const leaveMember = useCallback(
    async (requestBody: APIPaths["/app/influencer/me/cancel"]["put"]["requestBody"]["content"]["application/json"]) => {
      await setLoading(true);
      try {
        return await influencerApi.putCancelProfile(requestBody);
      } finally {
        await setLoading(false);
      }
    },
    [influencerApi],
  );

  return {
    fetchProfileMe,
    updateProfileMe,
    leaveMember,
  };
};
