import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class appAuthApi extends BaseApi {
  async influencerSignUp(
    requestBody: APIPaths["/app/auth/signup"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/signup"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/signup", requestBody)).data;
  }
  async checkAuthStatus(): Promise<
    APIPaths["/app/auth/status"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/app/auth/status")).data;
  }
  async syncSignedInAt(): Promise<
    APIPaths["/app/auth/sync-signed-in-at"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post("/app/auth/sync-signed-in-at")).data;
  }
}
